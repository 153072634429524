import httpClient from '@/services/http-client';

export async function getAvailableDocumentSeries(
  documentType,
  seriesYear = null,
  pageNumber = 0,
  perPage = 0,
) {
  const response = await httpClient.get('/api/adm/doc-series/list', {
    params: {
      documentType,
      seriesYear,
      pageNumber,
      perPage,
    },
  });

  return response.data;
}

export async function addDocumentSeries(payload) {
  const response = await httpClient.put('/api/adm/doc-series/item', payload);

  return response.data;
}

export async function removeDocumentSeries(documentType, seriesId) {
  const response = await httpClient.delete(`/api/adm/doc-series/item/${documentType}/${seriesId}`);

  return response.data;
}

export async function editDocumentSeries(payload) {
  const response = await httpClient.post(`/api/adm/doc-series/item/${payload.documentSeriesId}`, payload);

  return response.data;
}

export async function toggleDocumentSeriesActive(seriesId, isActive) {
  const response = await httpClient.post(`/api/adm/doc-series/active/${seriesId}`, { isActive });

  return response.data;
}
