import httpClient from '@/services/http-client';

export async function getAvailableProductClassifications() {
  const response = await httpClient.get('/api/adm/product-classification/list');
  return response.data;
}

export async function getAvailableAccounts() {
  const response = await httpClient.get('/api/adm/product-classification/accounts');
  return response.data;
}

export async function addProductClassification(payload) {
  const response = await httpClient.post('/api/adm/product-classification/item', payload);
  return response.data;
}

export async function editProductClassification(payload) {
  const response = await httpClient.put(`/api/adm/product-classification/item/${payload.clasificareProdusId}`, payload);
  return response.data;
}
