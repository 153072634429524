












































































import Decimal from 'decimal.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { toasts } from '@/utils/toasts';
import { constants } from '../../../../../utils/constants';

@Component
export default class extends Vue {
  actionType: string = 'add';
  documentType: string = '';
  documentSeriesId: Number = 0;
  documentTypeForDisplay: string = '';
  modalTitle: string = '';
  modalSubheader: string = '';
  yearMinimValue: number = 2010;
  yearMaximValue: number = 2029;
  yearSelected: string = new Date().getFullYear().toString();
  yearOptions: any[] = [];
  seriesCode: string = '';
  startIndex: string = '';
  zeroPadding: number = 0;
  endIndex: string = '';
  description: string = '';
  isEditType: boolean = false;
  errorMessage: string = '';

  documentSeriesCodeFormatter(value: string) {
    try {
      if (!value) {
        return '';
      }

      if (value.length > 10) {
        return value.substring(0, 10).toUpperCase();
      }

      return value.toUpperCase();
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  documentIndexFormatter(value: string) {
    try {
      if (!value) {
        return '';
      }
      // eslint-disable-next-line no-param-reassign
      value = value.trim();
      // eslint-disable-next-line no-param-reassign
      value = value.replace(/\D/g, '');
      return value;
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  documentDescriptionFormatter(value: string) {
    try {
      if (!value) {
        return '';
      }
      // eslint-disable-next-line no-param-reassign
      value = value.trim();
      return value;
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  formatter(input: string) {
    try {
      const asDecimal = new Decimal(input);
      if (asDecimal.isNaN()) {
        return '';
      }
      if (asDecimal.lte(0)) {
        return '';
      }
      return asDecimal.toFixed(0);
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  onOk(event) {
    if (!this.yearSelected) {
      toasts.error('Anul nu poate fi gol!');
      event.preventDefault();
      return;
    }
    if (!this.seriesCode) {
      toasts.error('Seria nu poate fi goală!');
      event.preventDefault();
      return;
    }
    if (!this.startIndex || !(parseInt(this.startIndex, 10) > 0)) {
      toasts.error('Numărul de început trebuie sa fie mai mare ca 0');
      event.preventDefault();
      return;
    }

    this.$emit('ok', {
      actionType: this.actionType,
      documentSeriesId: this.documentSeriesId,
      documentType: this.documentType,
      seriesYear: Number(this.yearSelected),
      seriesCode: this.seriesCode,
      startIndex: this.startIndex,
      description: this.description,
    });
  }

  modalTexts = {
    [constants.GRN]: {
      createTitle: 'Adaugă serie de NIR',
      editTitle: 'Editează serie de NIR',
      modalSubheader: 'Configurează mai jos noua serie de note de intrare în gestiune',
      documentTypeForDisplay: 'NIR',
    },

    [constants.MRN]: {
      createTitle: 'Adaugă serie de bonuri de consum',
      editTitle: 'Editează serie de bonuri de consum',
      modalSubheader: 'Configurează mai jos noua serie de bonuri de consum',
      documentTypeForDisplay: 'Bon de consum',
    },
  }

  showModal(data) {
    this.actionType = data.actionType;
    this.documentType = data.documentType;
    this.documentTypeForDisplay = data.documentType;
    this.documentSeriesId = data.documentSeriesId;

    if (this.documentTypeForDisplay === constants.GRN) {
      if (this.actionType === 'add') {
        this.modalTitle = 'Adaugă serie de NIR';
        this.isEditType = false;
      } else if (this.actionType === 'edit') {
        this.modalTitle = 'Editează serie de NIR';
        this.isEditType = true;
      }

      this.modalSubheader = 'Configurează mai jos noua serie de note de intrare în gestiune';
      this.documentTypeForDisplay = 'NIR';
    } else if (this.documentTypeForDisplay === constants.MRN) {
      if (this.actionType === 'add') {
        this.modalTitle = 'Adaugă serie de bonuri de consum';
        this.isEditType = false;
      } else if (this.actionType === 'edit') {
        this.modalTitle = 'Editează serie de bonuri de consum';
        this.isEditType = true;
      }

      this.modalSubheader = 'Configurează mai jos noua serie de bonuri de consum';
      this.documentTypeForDisplay = 'Bon de consum';
    } else if (this.documentTypeForDisplay === constants.MTN) {
      if (this.actionType === 'add') {
        this.modalTitle = 'Adaugă serie de bonuri de transfer între gestiuni';
        this.isEditType = false;
      } else if (this.actionType === 'edit') {
        this.modalTitle = 'Editează serie de bonuri de transfer între gestiuni';
        this.isEditType = true;
      }

      this.modalSubheader = 'Configurează mai jos noua serie de bonuri de transfer între gestiuni';
      this.documentTypeForDisplay = 'Bon de transfer între gestiuni';
    } else if (this.documentTypeForDisplay === constants.BCO) {
      if (this.actionType === 'add') {
        this.modalTitle = 'Adaugă serie de bonuri consum obiecte inventar';
        this.isEditType = false;
      } else if (this.actionType === 'edit') {
        this.modalTitle = 'Editează serie de bonuri consum obiecte inventar';
        this.isEditType = true;
      }

      this.modalSubheader = 'Configurează mai jos noua serie de bonuri consum obiecte inventar';
      this.documentTypeForDisplay = 'Bon consum obiecte inventar';
    } else if (this.documentTypeForDisplay === constants.BTO) {
      if (this.actionType === 'add') {
        this.modalTitle = 'Adaugă serie de bonuri transfer obiecte inventar';
        this.isEditType = false;
      } else if (this.actionType === 'edit') {
        this.modalTitle = 'Editează serie de bonuri transfer obiecte inventar';
        this.isEditType = true;
      }

      this.modalSubheader = 'Configurează mai jos noua serie de bonuri transfer obiecte inventar';
      this.documentTypeForDisplay = 'Bon transfer obiecte inventar';
    } else if (this.documentTypeForDisplay === constants.IDN) {
      if (this.actionType === 'add') {
        this.modalTitle = 'Adaugă serie de note de casare obiecte de inventar';
        this.isEditType = false;
      } else if (this.actionType === 'edit') {
        this.modalTitle = 'Editează serie de note de casare obiecte de inventar';
        this.isEditType = true;
      }

      this.modalSubheader = 'Configurează mai jos noua serie de note de casare obiecte de inventar';
      this.documentTypeForDisplay = 'Nota de casare obiecte de inventar';
    }

    this.yearSelected = this.actionType === 'edit' ? data.data.seriesYear : new Date().getFullYear();
    this.seriesCode = this.actionType === 'edit' ? data.data.seriesCode : '';
    this.startIndex = this.actionType === 'edit' ? data.data.startIndex : '';
    this.description = this.actionType === 'edit' ? data.data.description : '';
    this.errorMessage = '';

    for (let i = this.yearMinimValue; i <= this.yearMaximValue; i += 1) {
      this.yearOptions.push({
        text: i.toString(),
        value: i.toString(),
      });
    }

    (<any>(this.$refs.modal)).show();
  }
}

