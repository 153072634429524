


































































































import Vue from 'vue';
import Component from 'vue-class-component';
import camelcaseKeys from 'camelcase-keys';
import { toasts } from '@/utils/toasts';
import i18n from '@/i18n';

import AddEditProductClassificationModal from './AddEditProductClassificationModal.vue';

import {
  getAvailableProductClassifications,
  addProductClassification,
  editProductClassification,
} from './product-classification-api';

import { constants } from '../../../utils/constants';

@Component({
  components: {
    AddEditProductClassificationModal,
  },
})
export default class extends Vue {
  hoveredRowIndex = -1;
  items: Array<any> = [];
  totalConturi: Array<any> = [];
  tableBusy = false;
  actionType: string = 'add';
  selectedItem: any | null = null;
  newContDiferentePretSelection: any | null = null;

  get fields() {
    return [
      {
        key: 'denumire',
        label: i18n.t('applicationSettings.clasificareProduse.grid.denumire'),
      },
      {
        key: 'tip',
        label: i18n.t('applicationSettings.clasificareProduse.grid.tip'),
      },
      {
        key: 'contGestiune',
        label: i18n.t('applicationSettings.clasificareProduse.grid.contGestiune'),
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'contCheltuiala',
        label: i18n.t('applicationSettings.clasificareProduse.grid.contCheltuiala'),
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'contVenit',
        label: i18n.t('applicationSettings.clasificareProduse.grid.contVenit'),
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'contDiferentePret',
        label: i18n.t('applicationSettings.clasificareProduse.grid.contDiferentaPret'),
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'actiuni',
        label: i18n.t('applicationSettings.clasificareProduse.grid.actiuni'),
      },
    ];
  }

  tipName(tip) {
    switch (tip) {
      case constants.productClassTypes.product:
        return 'Produs';
      case constants.productClassTypes.service:
        return 'Serviciu';
      default:
        return '-';
    }
  }

  async onExcelExport() {
    window.location.href = '/api/adm/product-classification/excel-export';
  }

  created() {
    if (!this.$store.getters.canAdminister) {
      this.$router.replace({ name: 'Dashboard' });
      return;
    }

    this.initialize();
  }

  async initialize() {
    await this.loadGridPage();
  }

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  async addProductClassificationEvent(item) {
    try {
      await addProductClassification(item);
    } catch (err) {
      toasts.error('Eroare la salvarea informațiilor');
    } finally {
      await this.loadGridPage();
    }
  }

  async editProductClassificationEvent(item) {
    try {
      await editProductClassification(item);
    } catch (err) {
      toasts.error('Eroare la salvarea modificărilor');
    } finally {
      await this.loadGridPage();
    }
  }

  async onAddClassification() {
    this.actionType = 'add';
    (<any>(this.$refs.addEditProductClassificationModal)).showModal(
      this.items,
      null,
      this.actionType,
    );
  }

  async onEditClassification(item) {
    this.actionType = 'edit';
    this.selectedItem = item;
    (<any>(this.$refs.addEditProductClassificationModal)).showModal(
      this.items,
      item,
      this.actionType,
    );
  }

  async onSubmitProductClassification() {
    await this.loadGridPage();
  }

  async loadGridPage() {
    try {
      this.tableBusy = true;
      const result = camelcaseKeys(await getAvailableProductClassifications());
      this.items = result.items;
    } catch (err) {
      toasts.error('Eroare la încărcarea clasificărilor de produse');
    } finally {
      this.tableBusy = false;
    }
  }
}

